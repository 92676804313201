<template>
    <b-card no-body class="p-1">
        <b-overlay :show="state.loading">
            <b-form class="d-flex flex-column" @submit.prevent="sendData">
                <b-row>
                    <b-col cols="12" md="4" v-for="(data,index) in items" :key="index" v-show="index !== 'privacyPolicy'">
                        <b-form-group :label="label[index]">
                            <b-form-input v-model="items[index]"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="محتوی قوانین و مقررات سایت">
                            <froala v-model="items.privacyPolicy" :config="config"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-button variant="primary" type="submit" class="align-self-end">ذخیره</b-button>
            </b-form>
            <!--<b-form-group class="p-1 m-1 border" label="نمونه خروجی فایل راهنما">
                <froalaView v-model="items.privacyPolicy"/>
            </b-form-group>-->
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BButton,
        BCard,
        BForm,
        BFormGroup,
        // BFormTextarea,
        BFormInput,
        BRow,
        BCol,
        BOverlay
    } from "bootstrap-vue";

    const label = {
        "address": "آدرس",
        "appAndroid": "اپ اندروید",
        "appIOS": "اپ آی او اس",
        "email": "ایمیل",
        "instagram": "اینستاگرام",
        "linkedin": "لینکدین",
        "number": "شماره تلفن",
        "telegram": "تلگرام",
        "twitter": "توییتر",
        "privacyPolicy": "محتوی قوانین و مقررات"
    }

    export default {
        name: "SiteInfo",
        components: {
            BCard,
            BButton,
            BForm,
            BFormGroup,
            // BFormTextarea,
            BFormInput,
            BRow,
            BCol,
            BOverlay
        },
        data: () => ({
            items: {
                "address": "",
                "number": "",
                "email": "",
                "appAndroid": "",
                "appIOS": "",
                "instagram": "",
                "linkedin": "",
                "telegram": "",
                "twitter": "",
                "privacyPolicy":""
            },
            label,
            config: {
                events: {
                    'initialized': function () {
                        console.log('floara inited')
                    },
                    'image.beforeUpload': function (files) {
                        let editor = this;
                        if (files.length) {
                            // Create a File Reader.
                            let reader = new FileReader();
                            // Set the reader to insert images when they are loaded.
                            reader.onload = function (e) {
                                let result = e.target.result;
                                editor.image.insert(result, null, null, editor.image.get());
                            };
                            // Read image as base64.
                            reader.readAsDataURL(files[0]);
                        }
                        editor.popups.hideAll();
                        // Stop default upload chain.
                        return false;
                    }
                },
                language: 'fa',
                direction: 'rtl',
                tooltips: false,
                toolbarButtons: {
                    'moreText': {
                        'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                    },
                    'moreParagraph': {
                        'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'formatOLSimple', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                    },
                    'moreRich': {
                        'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR']
                    },
                    'moreMisc': {
                        'buttons': ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                        'align': 'right',
                        'buttonsVisible': 2
                    }
                },
                fontFamily: {
                    'Yekan': 'یکان',
                },
                fontFamilyDefaultSelection: 'Yekan',
                fontFamilySelection: false,
                key: "1C%kZV[IX)_SL}UJHAEFZMUJOYGYQE[\\ZJ]RAe(+%$==",
                attribution: false // to hide "Powered by Froala"
            },
        }),
        methods: {
            async getData() {
                this.state.loading = true
                const res = await this.$axios.get('/exchange-info')
                this.items = res.data.baseDTO
            },
            sendData: async function () {
                this.state.loading = true
                const res = await this.$axios.post('/exchange-info', this.items)
                if(res) {
                    this.$swal({
                icon: 'success',
                title: 'محتوی قوانین و مقررات سایت ذخیره شد',
                confirmButtonText: 'متوجه شدم',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
                }
                this.getData()
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss">
    .fr-box {
        /*text-align: left;*/
        p,ul,li,ol {
            text-align: left;
            font-family: 'Yekan', 'IRANSans', sans-serif !important;
        }

    }
</style>